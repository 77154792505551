import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from '../components';
import { requestPinRaw, requestPinCurl, requestPinJava, requestPinJavascript, requestPinNode, requestPinPerl, requestPinPython, requestPinPhp, requestPinRuby, requestPinGo, requestPinC, requestPinVB, requestPinGroovy, requestPinObjectiveC, requestPinSwift, requestPinDelegationTokenRaw, requestPinDelegationTokenCurl, requestPinDelegationTokenJava, requestPinDelegationTokenJavascript, requestPinDelegationTokenNode, requestPinDelegationTokenPerl, requestPinDelegationTokenPython, requestPinDelegationTokenPhp, requestPinDelegationTokenRuby, requestPinDelegationTokenGo, requestPinDelegationTokenC, requestPinDelegationTokenVB, requestPinDelegationTokenGroovy, requestPinDelegationTokenObjectiveC, requestPinDelegationTokenSwift } from 'requestExamples/authorization';
export const basePostUrl = [{
  param: 'POST',
  value: '/pin/end'
}];
export const delegationTokenPostUrl = [{
  param: 'POST',
  value: '/pin/auth'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  basePostUrl,
  delegationTokenPostUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pin-based-authentication"
    }}>{`Pin based authentication`}</h1>
    <p>{`Using this technique, the user inputs a PIN code into your application using an out-of-band technique.`}<br /><br />{`
Typically, the user is presented with the PIN from the SmartVault web server and performs a copy and paste operation to put the PIN into your application. Your application exchanges the PIN code for an access token.`}<br /><br />{`
Pin numbers are specific to each application authorized by each user.`}</p>
    <br />
    <br />
    <h2 {...{
      "id": "request-a-pin"
    }}>{`Request a pin`}</h2>
    <InfoIcon mdxType="InfoIcon" /> For each user who uses your application, you will need to have SmartVault generate a pin for your user.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> The pin is used to authenticate each API call, and only needs to be generated once for each user.
    <br />
    <br />
    <InfoIcon mdxType="InfoIcon" /> The pin is not directly returned to your application. Instead, you will get a unique URL that the user will
need to visit in order to authorize your application in SmartVault. The user will be given their pin number, which they
will need to enter into your application so you can authenticate with SmartVault.
    <br />
    <br />
    <br />

    <Row mdxType="Row">
	<div>
		<p>
			This call is used to request a pin from SmartVault. It returns a URL for the user to visit to authorize the
			request and retrieve their pin.
		</p>
	</div>
	<Table title="Request pin" rows={basePostUrl} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'client_id',
      type: 'string',
      description: 'The client ID as registered in SmartVault.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "client_id": "SampleCRMWeb"
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: requestPinRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: requestPinCurl
    }, {
      tabTitle: 'Java',
      tabInfo: requestPinJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: requestPinJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: requestPinNode
    }, {
      tabTitle: 'Perl',
      tabInfo: requestPinPerl
    }, {
      tabTitle: 'Python',
      tabInfo: requestPinPython
    }, {
      tabTitle: 'PHP',
      tabInfo: requestPinPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: requestPinRuby
    }, {
      tabTitle: 'Go',
      tabInfo: requestPinGo
    }, {
      tabTitle: 'C#',
      tabInfo: requestPinC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: requestPinVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: requestPinGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: requestPinObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: requestPinSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the authorization uri.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
  "error": {
    "success": true
  },
  "message": {
    "authorization_uri": "https://my.smartvault.com/users/secure/IntegratedApplications.aspx?c=RoRyq8s3SUSXgXxvX6thKw"
  }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the client id is not specified in the request body.`}</p>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "request-a-delegation-token"
    }}>{`Request a delegation token`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			If you are using pin-based authentication, you will need the user's pin in order to get a delegation token.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			No "Authorization" header is required.
			<br />
			<br />
			This call is used to request a delegation token. This is the token you will use to authenticate each time your
			application makes a call to the API.
		</p>
	</div>
	<Table title="Request delegation token" rows={delegationTokenPostUrl} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'user_email',
      type: 'string',
      description: "The user account's email address."
    }, {
      name: 'client_id',
      type: 'string',
      description: 'The client ID as registered in SmartVault.'
    }, {
      name: 'pin',
      type: 'string (optional)',
      description: 'The pin number assigned to the email and client ID. This is used only for pin-based authentication.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "user_email": "testuser@smartvault.com",
  "pin_info": {
    "client_id": "SampleCRMWeb",
    "pin": 341450484
  }
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: requestPinDelegationTokenRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: requestPinDelegationTokenCurl
    }, {
      tabTitle: 'Java',
      tabInfo: requestPinDelegationTokenJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: requestPinDelegationTokenJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: requestPinDelegationTokenNode
    }, {
      tabTitle: 'Perl',
      tabInfo: requestPinDelegationTokenPerl
    }, {
      tabTitle: 'Python',
      tabInfo: requestPinDelegationTokenPython
    }, {
      tabTitle: 'PHP',
      tabInfo: requestPinDelegationTokenPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: requestPinDelegationTokenRuby
    }, {
      tabTitle: 'Go',
      tabInfo: requestPinDelegationTokenGo
    }, {
      tabTitle: 'C#',
      tabInfo: requestPinDelegationTokenC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: requestPinDelegationTokenVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: requestPinDelegationTokenGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: requestPinDelegationTokenObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: requestPinDelegationTokenSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the delegation token, its expiracy and the id related to the user used for retrieving it.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "token": "REVMMDAAAAAAAAASdQCQwerAQ0rDiBYb79v70bLOQOiI6cKBNWHmgUfNMJzZfUYM889NlX+llum66rNlA4iYtqoSL/UW0z9X6MIziVUF",
        "expires": 1209600,
        "id": "User:QVqGsFoKkU-OAx2KownMFw"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if any parameters are missing.`}</p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      